<template>
    <div>
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        created: function () {
            this.$axios({
                url: '/api/comon/getVersion_android',
            }).then(res => {                
                if (res.data.code == 0) {
                    location.href = res.data.data.url;
                }
            }).catch(error => { // 请求失败处理
            });
        },
        methods: {
            
        }
    }


    
</script>
